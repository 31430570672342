


















import Vue from "vue";
import AboutCard from "@/components/AboutCard.vue";
import ContactCard from "@/components/ContactCard.vue";
import GaCard from "@/components/GaCard.vue";
export default Vue.extend({
  components: {
    AboutCard,
    ContactCard,
    GaCard,
  },
});
